import { EventBus } from '@/assets/js/helpers'
import { trackEvent } from '@/utils/googleAnalytics'
import { trackGoal } from '@/utils/plausible'
import companyApi from '../lasso-api/company'
import { Contacts } from '../lasso-api/company/types'
import api from './api'
import FollowedEntities, { FollowedEntity, ResponseFollowedEntity } from './FollowedEntities'
import gravatar from './gravatar'
import userStore from './store'

export default class User {
  id: string
  // created: Date
  // acceptedTerms: boolean
  // acceptedMarketing: boolean
  followedEntities: FollowedEntities
  // contactCrawlerLimit: ProviderLimit<'LassoContactCrawler'>

  constructor (response: ResponseUser) {
    this.id = response.id
    // this.created = new Date(response.created)
    // this.acceptedTerms = response.acceptedTerms != null
    // this.acceptedMarketing = response.acceptedMarketing != null
    // this.followedEntities = new FollowedEntities(response.followedEntities.map(fe => new FollowedEntity(fe)))
    this.followedEntities = new FollowedEntities()
    // this.contactCrawlerLimit = new ProviderLimit(response.requestLimits[0])
  }

  // setContactCrawlerLimit (limit: User['contactCrawlerLimit']) {
  //   if (limit.usedRequests != this.contactCrawlerLimit.usedRequests) {
  //     const remainder = limit.totalRequests - limit.usedRequests

  //     EventBus.$emit('alert', {
  //       content: `Du har nu ${remainder} kontaktopslag tilbage`,
  //       duration: 5000,
  //     })
  //   }

  //   this.contactCrawlerLimit = limit
  // }

  // async followEntities (entities: EntityReference[]) {
  //   if (!entities.length) return

  //   const firstEntity = entities[0]

  //   this.followedEntities.add(entities.map(e => new FollowedEntity({
  //     lassoId: e.lassoId,
  //     name: e.name,
  //     added: new Date,
  //   })))

  //   const response = await api.follow(entities.map(e => e.lassoId))

  //   if (response.ok) {
  //     trackGoal('Følg ' + (firstEntity.lassoId.indexOf('CVR-1-') === 0 ? 'firma' : 'person'), { lassoId: firstEntity.lassoId })

  //     if (entities.length == 1) {
  //       EventBus.$emit('alert', {
  //         content: `Du overvåger nu ${firstEntity.name}`,
  //         duration: 5000,
  //       })
  //     }
  //   } else if (response.status == 401) {
  //     userStore.unsetUser()

  //     if (entities.length === 1) {
  //       userStore.showLoginToFollowDialog({
  //         entity: firstEntity,
  //       })
  //     } else {
  //       userStore.showLoginDialog({
  //         onLoggedIn: () => userStore.user?.followEntities(entities),
  //       })
  //     }
  //   }
  // }

  // async unfollowEntities (entities: EntityReference[]) {
  //   if (!entities.length) return

  //   const firstEntity = entities[0]

  //   this.followedEntities.remove(entities.map(e => e.lassoId))

  //   const response = await api.unfollow(entities.map(e => e.lassoId))

  //   if (response.ok) {
  //     trackEvent({
  //       eventCategory: 'Stop med at overvåge',
  //       eventAction: firstEntity.lassoId.startsWith('CVR-1-')
  //         ? 'Firma'
  //         : 'Person',
  //       eventLabel: window.location.pathname,
  //     })

  //     if (entities.length == 1) {
  //       EventBus.$emit('alert', {
  //         content: `Du overvåger ikke længere ${firstEntity.name}`,
  //         duration: 5000,
  //       })
  //     }
  //   } else if (response.status == 401) {
  //     userStore.unsetUser()

  //     userStore.showLoginDialog({
  //       onLoggedIn: () => {
  //         userStore.user?.unfollowEntities(entities)
  //       },
  //     })
  //   }
  // }

  // async getCompanyContactInfo (lassoId: string, contactHistory: Contacts.HistoryContact) {
  //   const response = await companyApi.getContactInfo(lassoId, contactHistory)
  //   this.setContactCrawlerLimit(new ProviderLimit(response.limit))
  //   return response
  // }
}

export interface ResponseUser {
  id: string
  // created: string
  // acceptedTerms: string | null
  // acceptedMarketing: string | null
  // followedEntities: ResponseFollowedEntity[]
  // requestLimits: [ ResponseProviderLimit<'LassoContactCrawler'> ]
}

interface EntityReference {
  lassoId: string
  name: string
}

export class ProviderLimit<Provider extends string> {
  provider: Provider
  periodEnd: Date
  usedRequests: number
  totalRequests: number
  remainingRequests: number

  constructor (response: ResponseProviderLimit<Provider>) {
    this.provider = response.provider
    this.periodEnd = new Date(response.periodEnd)
    this.usedRequests = response.usedRequests
    this.totalRequests = response.totalRequests
    this.remainingRequests = response.totalRequests - response.usedRequests
  }
}

export interface ResponseProviderLimit<Provider extends string> {
  provider: Provider
  periodEnd: string
  usedRequests: number
  totalRequests: number
}
