<template>
  <div class="error">
    <Icon icon="error" />

    <p>{{ error }}</p>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import Icon from 'vue-lasso-core/components/Icon.vue'

@Component({
  components: {
    Icon,
  },
})

export default class Error extends Vue {
  @Prop({ type: String, required: true })
  readonly error!: string
}
</script>

<style lang="scss" scoped>
@import '@shared/style/global.scss';

.error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: getTextColor('primary');
  text-align: center;

  .md-icon {
    margin-bottom: 4px;
    color: inherit;
  }

  p {
    margin: -4px 0;
  }
}
</style>
