<template>
  <transition :duration="{ enter: 150, leave: 75 }">
    <Dialog
      v-if="store.state.show"
      class="confirm-email-sent-dialog"
      :options="{
        size: 400,

        header: {
          title: 'Bekræft din e-mail',
          closeButton: {
            show: true,
            onClick: () => store.hide(),
          },
        },

        backdrop: {
          show: true,
          onClick: () => store.hide(),
        },
      }"
    >
      <div class="banner">
        <div class="banner-image" />
      </div>

      <div class="body">
        <p>Du er der næsten!</p>
        <p>Vi har sendt en mail til <strong>{{ store.state.email }}</strong>.</p>
        <p>Klik på knappen i mailen for at komme i gang.</p>
        <p v-if="store.state.lassoOrganizationId">
          <br>
          Har du ikke modtaget mailen? <a @click="resendWelcomeEmail" href="#">Send igen.</a>
          <br>
        </p>
        <p v-if="sentTime">Sendt kl. {{ sentTime.toLocaleTimeString("da-DK") }}.</p>
      </div>
    </Dialog>
  </transition>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import Dialog from 'vue-lasso-core/components/Dialog.vue'
import store from './store'
import userApi from '@/modules/users/api'

@Component({
  components: {
    Dialog,
  },
})

export default class ConfirmEmailSentDialog extends Vue {
  store = store
  sentTime: Date | null = null

  async resendWelcomeEmail() {
    await userApi.resendWelcomeMail(this.store.state.email, this.store.state.lassoOrganizationId, this.store.state.emailRedirectUrl)
    this.sentTime = new Date()
  }
}
</script>

<style lang="scss" scoped>
@import '@shared/style/global.scss';

@mixin deep { ::v-deep { @content } }

.confirm-email-sent-dialog {
  &.v-enter-active {
    @include deep {
      & > .backdrop {
        transition: opacity 150ms linear;
        opacity: 0;
      }

      & > .dialog {
        transition: opacity 45ms linear, transform 150ms ease-out;
        opacity: 0;
        transform: scale(0.8);
      }
    }

    &.v-enter-to {
      @include deep {
        & > .backdrop {
          opacity: 1;
        }

        & > .dialog {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }

  &.v-leave-active {
    transition: opacity 75ms linear;
    opacity: 1;

    &.v-leave-to {
      opacity: 0;
    }
  }
}

.confirm-email-sent-dialog {
  z-index: 999999;

  .banner {
    position: relative;
    height: 200px;
    margin: 0 -24px;
    margin-bottom: 24px;
    overflow: hidden;

    .banner-image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url(~@/assets/img/lasso-x-mail.png);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .body {
    text-align: center;

    p {
      margin: -4px 0;

      & + p {
        margin-top: 8px - 4px;
      }
    }

    strong {
      font-weight: 500;
    }
  }
}
</style>
