var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('transition',{attrs:{"duration":{ enter: 150, leave: 75 }}},[(_vm.store.state.show)?_c('Dialog',{staticClass:"external-login-welcome-dialog",attrs:{"options":{
      size: 360,

      header: {
        title: 'Tillyke, du er oprettet!',
        closeButton: {
          show: true,
          onClick: () => _vm.store.hide(),
        },
      },

      backdrop: {
        show: true,
        onClick: () => _vm.store.hide(),
      },
    }}},[_c('AcceptMarketingField',{model:{value:(_vm.acceptMarketing),callback:function ($$v) {_vm.acceptMarketing=$$v},expression:"acceptMarketing"}}),_c('div',{staticClass:"footer"},[_c('Button',{staticClass:"ml-auto",attrs:{"theme":"red","disabled":!_vm.acceptMarketing,"label":"Fortsæt"},on:{"click":() => {
          _vm.userApi.acceptMarketing()
          _vm.store.hide()
        }}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }