import Vue from 'vue'

const sizes = [
  [ 'xs', 544 ],
  [ 'sm', 767 ],
  [ 'md', 992 ],
  [ 'lg', 1200 ],
  [ 'xl', 1520 ],
] as const

type SizeName = typeof sizes[number][0]

const windowSize = Vue.observable({
  width: 0,
  height: 0,

  get sizes () {
    const obj: Record<string, number> = {}
    for (const [ name, width ] of sizes) obj[name] = width
    return obj as Record<SizeName, number>
  },

  get size () {
    const { width } = windowSize
    if (width < 480) return 'xxs'
    if (width < 768) return 'xs'
    if (width < 992) return 'sm'
    if (width < 1200) return 'md'
    if (width < 1520) return 'lg'
    return 'xl'
  },
})

const update = () => {
  windowSize.width = window.innerWidth
  windowSize.height = window.innerHeight
}

update()
window.addEventListener('resize', update)

export default windowSize
