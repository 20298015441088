import { render, staticRenderFns } from "./CustomRadioButton.vue?vue&type=template&id=2280bbe5&scoped=true"
import script from "./CustomRadioButton.vue?vue&type=script&lang=ts"
export * from "./CustomRadioButton.vue?vue&type=script&lang=ts"
import style0 from "./CustomRadioButton.vue?vue&type=style&index=0&id=2280bbe5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../Lasso.LassoDK/app/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2280bbe5",
  null
  
)

export default component.exports