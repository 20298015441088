var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('transition',{attrs:{"duration":{ enter: 150, leave: 75 }}},[_c('Dialog',{class:[
      'login-dialog-template',
      _vm.sizes.map(size => `is-size-${size}`),
    ],attrs:{"options":{
      size: _vm.size,

      dense: true,

      scrollable: true,

      backdrop: {
        show: true,
        onClick: () => _vm.userStore.hideLoginDialog({ dismissed: true }),
      },
    }}},[_c('div',{staticClass:"login-dialog-columns"},_vm._l((_vm.columns),function(column){return _c('div',{key:column,class:`${column}-column ${_vm.options.lassoxIntro ? _vm.options.lassoxIntro.color : 'peach'}`},[(column == 'login')?[_c('div',{staticClass:"login"},[(_vm.router.current)?_c(_vm.router.current.component,_vm._b({tag:"component"},'component',{
                options: _vm.options,
                ...(_vm.router.current.props || {}),
              },false)):_vm._e()],1)]:(column == 'intro')?[_c('div',{staticClass:"intro-background-cutoff"}),_c('div',{staticClass:"intro-content"},[_c('div',{staticClass:"intro-logo-wrapper"},[_c('img',{staticClass:"intro-logo",attrs:{"src":require("@/assets/img/lasso-x-logo-white.svg?inline")}})]),_c('div',{staticClass:"m-auto"}),_c('h1',{staticClass:"intro-title",domProps:{"innerHTML":_vm._s(_vm.options.lassoxIntro ? _vm.options.lassoxIntro.title : 'Få flere muligheder med <br>en gratis profil')}}),_c('p',{staticClass:"intro-text",domProps:{"innerHTML":_vm._s(_vm.options.lassoxIntro ? _vm.options.lassoxIntro.text : 'Opret en profil og få større indsigt i dansk erhvervsliv. Du får bl.a. adgang til nye værktøjer og flere oplysninger om regnskaber, historik og ejerforhold &ndash; alt sammen helt gratis.')}}),_c('div',{staticClass:"m-auto"})]),_c('div',{staticClass:"intro-image-wrapper",class:_vm.options.lassoxIntro && _vm.options.lassoxIntro.image.includes('signup-dialog-creditsafe-phone') ? 'intro-image-phone' : ''},[_c('img',{staticClass:"intro-image",attrs:{"alt":"Et udsnit af mulighederne i Lasso X","src":_vm.options.lassoxIntro ? _vm.options.lassoxIntro.image : require('@/assets/img/signup-laptop-platform.png')}})])]:_vm._e()],2)}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }