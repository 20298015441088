import Vue from 'vue'
export default {
	number: function test (str) {
		return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
	},
	timeSince: function (date) {
		var now = new Date()
		var diff = new Date(date).getTime() - now.getTime()
		var seconds = Math.abs(Math.round(diff / 1000))
		if (seconds < 45) return ' få sekunder siden'
		var minutes = Math.round(seconds / 60)
		if (minutes < 45) return minutes + (minutes === 1 ? ' minut ' : ' minutter ') + 'siden'
		var hours = Math.round(minutes / 60)
		if (hours < 22) return hours + (hours === 1 ? ' time ' : ' timer ') + 'siden'
		var days = Math.round(hours / 24)
		if (days < 26) return days === 1 ? 'I går' : (days + ' dage siden')
		var months = Math.round(days / 31)
		if (months < 11) return months + (months === 1 ? ' måned ' : ' måneder ') + 'siden'
		var years = Math.round(days / 365)
		return years + ' år siden'
	},
	daysBetween: function (date1, date2) {
		var diff = date1.getTime() - date2.getTime()
		return Math.abs(Math.round(diff / (1000 * 60 * 60 * 24)))
	},
	pluralize: function (count, zeroText, oneText, multipleText) {
		if (count === 0) return count + ' ' + zeroText
		else if (count === 1) return count + ' ' + oneText
		else return count + ' ' + (multipleText || zeroText)
	},
	truncateAlways: function (text, length) {
		if (text) {
			if (text.length <= length) {
				return text.substring(0, text.length - 4) + ' ...'
			} else {
				return Vue.filter('truncate')(text, length, ' ...', true)
			}
		} else return ''
	},
	monthName: function (month) {
		switch (month) {
			case 0: return 'januar'
			case 1: return 'februar'
			case 2: return 'marts'
			case 3: return 'april'
			case 4: return 'maj'
			case 5: return 'juni'
			case 6: return 'juli'
			case 7: return 'august'
			case 8: return 'september'
			case 9: return 'oktober'
			case 10: return 'november'
			case 11: return 'december'
		}
	},
	linkify: function (text, doNotCreateLinks, truncate, nameCache) {
		if (text) {
			var regex = /{([^}]*)}/g
			var regex2 = /\{([^}]+)\}/
			// find all links, take unique
			var links = text.match(regex)
			// for each, generate html or simple text depending on doNotCreateLinks param
			if (links) {
				links.forEach(function (link) {
					var content = link.match(regex2)[1].split('|')
					if (content.length === 2) {
						if (doNotCreateLinks) text = text.replace(link, content[0])
						else {
							var nameSlug = nameCache ? (nameCache[content[1]] || null) : null
							text = text.replace(link, '<router-link exact ' + (content[1].indexOf('CVR-1-') === 0 ? 'class="plausible-event-name=click+company+link"' : '') + ' :to="{path: \'/' + (content[1].indexOf('CVR-1') === 0 ? 'firmaer' : 'personer') + '/' + content[1].substring(6) + (nameSlug ? '/' + nameSlug : '') + '\'}">' + content[0] + '</router-link>')
						}
					}
				})
			}
			return truncate ? Vue.filter('truncateAlways')(text, truncate) : text
		}
	},
	metaDescription (text) {
		text = text.replace(new RegExp('/li>', 'g'), '/li>, ').replace(/<[^>]*>/g, '')
		return Vue.filter('truncate')(text, 300)
	}
}
