const providers = [
  'LinkedIn',
  'Facebook',
  'Microsoft',
  // 'Google',
  // 'Twitter',
] as const

export default providers

export type Provider = typeof providers[number]
