<template>
  <LoginDialogTemplate
    v-if="show"
    class="login-dialog"
    :options="options"
  >
    <template v-slot:intro-title>
      Få adgang til mere og skræddersy dit <br/>Lasso-feed med en <span class="highlight-text">gratis profil</span>
    </template>

    <template v-slot:intro-text>
      <p>Du får adgang til:</p>
      <ul>
        <!-- <p><br></p> -->
        <li class="mt-3">Kontaktoplysninger på personer</li>
        <li>Flere regnskabs- og nøgletal</li>
        <!-- <li>Telefonnumre fra de danske teleoperatører</li> -->
        <li>Overvågning i real-tid af dine<br>foretrukne virksomheder</li>
      </ul>
    </template>
  </LoginDialogTemplate>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import LoginDialogTemplate from '@/components/LoginDialogTemplate/LoginDialogTemplate.vue'
import userStore from '@/modules/users/store'

@Component({
  components: {
    LoginDialogTemplate,
  },
})

export default class LoginDialog extends Vue {
  get options () {
    return userStore.loginDialogOptions
  }

  get show () {
    return !!this.options
  }
}
</script>

<style lang="scss" scoped>
@import '@shared/style/global.scss';

@mixin deep { ::v-deep { @content } }

.login-dialog {

  .highlight-text {
    color: #FF8E61;
  }

  @include deep {
    .login-dialog-columns .intro-column .intro-content {

      ul li {
          text-align: left;
        display: flex;

        & + li {
          margin-top: 4px;
        }

        &::before {
          content: '\2022';
          display: inline-block;
          margin-right: 8px;
          font-size: 20px;
          vertical-align: top;
        }
      }
    }
  }
}
</style>
