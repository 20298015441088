<template>
  <svg class="spinner" :width="size" :height="size" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
    <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
  </svg>  
</template>

<script>
export default {
  name: 'loader',
  props: {
    size: {
      type: Number,
      default: 65
    }
  }
}
</script>

<style lang="less" scoped>
  .spinner {
  -webkit-animation: rotator 2.1s linear infinite;
          animation: rotator 2.1s linear infinite;
}
@-webkit-keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.path {
  stroke-dasharray: 188;
  stroke-dashoffset: 47;
  transform-origin: center;
  -webkit-animation: dash 1.4s ease-in-out infinite, dashRotation 5.6s ease-in-out infinite, colors 5.6s ease-in-out infinite;
          animation: dash 1.4s ease-in-out infinite, dashRotation 5.6s ease-in-out infinite, colors 5.6s ease-in-out infinite;
}
@-webkit-keyframes colors {
  0% {
    stroke: #FAE9C4;
  }
  25% {
    stroke: #F09782C4;
  }
  50% {
    stroke: #EE8A78;
  }
  75% {
    stroke: #AB9EF5;
  }
  100% {
    stroke: #FAE9C4;
  }
}
@keyframes colors {
  0% {
    stroke: #FAE9C4;
  }
  25% {
    stroke: #F09782C4;
  }
  50% {
    stroke: #EE8A78;
  }
  75% {
    stroke: #AB9EF5;
  }
  100% {
    stroke: #FAE9C4;
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 188;
  }
  50% {
    stroke-dashoffset: 47;
  }
  100% {
    stroke-dashoffset: 188;
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 188;
  }
  50% {
    stroke-dashoffset: 47;
  }
  100% {
    stroke-dashoffset: 188;
  }
}
@-webkit-keyframes dashRotation {
  0% {
    transform: rotate(0deg);
  }
  12.5% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(270deg);
  }
  37.5% {
    transform: rotate(270deg);
  }
  50% {
    transform: rotate(540deg);
  }
  62.5% {
    transform: rotate(540deg);
  }
  75% {
    transform: rotate(810deg);
  }
  87.5% {
    transform: rotate(810deg);
  }
  100% {
    transform: rotate(1080deg);
  }
}
@keyframes dashRotation {
  0% {
    transform: rotate(0deg);
  }
  12.5% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(270deg);
  }
  37.5% {
    transform: rotate(270deg);
  }
  50% {
    transform: rotate(540deg);
  }
  62.5% {
    transform: rotate(540deg);
  }
  75% {
    transform: rotate(810deg);
  }
  87.5% {
    transform: rotate(810deg);
  }
  100% {
    transform: rotate(1080deg);
  }
}

</style>
