<template>
	<div id="SideBanners">
		<!-- left banner -->
		<div v-if="windowSize.width > 1200 && isAdPage" :class="{'fill-space': leftBannerLoaded}">
			<Banner @filled="leftBannerLoaded = true" bannerType="bm-sky1" class="left" :class="{sticky:stickSideBanners}" />
		</div>
		<!-- page content -->
		<slot />
		<!-- right banner -->
		<div v-if="windowSize.width > 1520 && isAdPage" :class="{'fill-space': rightBannerLoaded}">
			<Banner @filled="rightBannerLoaded = true"  bannerType="bm-sky2" class="right" :class="{sticky:stickSideBanners}" />
		</div>
	</div>
</template>

<script>
import Banner from './Banner.vue'
import windowSize from '@/window-size'
export default {
	data () {
		return {
      windowSize,
			stickSideBanners: false,
			leftBannerLoaded: false,
			rightBannerLoaded: false
		}
	},
	computed: {
		isAdPage () {
			return ['home', 'companyStory', 'person', 'company'].indexOf(this.$route.name) > -1
		}
	},
	mounted () {
		if (!this.$store.state.isAdTester) return

		// configure stickyness when scrolling
		let reqAnimationFrame = window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame
		// let bannerOffset = null
		let latestKnownScrollY = 0
		let ticking = false
		let el = this.$el
		let onScroll = () => {
			if (!el) return
			latestKnownScrollY = window.pageYOffset || window.scrollY || document.documentElement.scrollTop
			let bannerOffset = el.getBoundingClientRect().top + latestKnownScrollY - document.getElementById('app-header').offsetHeight - 30
			if (!ticking) {
				reqAnimationFrame(() => {
					ticking = false
					this.stickSideBanners = latestKnownScrollY > bannerOffset
				})
				ticking = true
			}
		}
		document.addEventListener('scroll', onScroll, false)
	},
	components: {
		Banner
	}
};
</script>

<style lang="less">
#SideBanners {
	display: flex;
	justify-content: center;
	margin: 0 auto;

	.left {
		margin-right: 30px;
	}

	.right {
		margin-left: 30px;
	}

	.fill-space {
		min-width: 190px;
	}

	.banner.sticky {
		position: fixed;
		top: 110px;
		&.right {
			// margin-left: -160px;
		}
	}
}

#app {
	&.xs {
		#SideBanners {
			padding: 0 15px;
		}
	}

	&.sm, &.md, &.lg {
		#SideBanners {
			padding: 0 30px;

		}
	}

	&.lg {
		#SideBanners {
			max-width: 1200px;
		}
	}

	&.xl {
		#SideBanners {
			max-width: 1520px;
		}
	}



}
</style>
