<template>
  <transition :duration="{ enter: 150, leave: 75 }">
    <Dialog
      v-if="store.state.show"
      class="forgot-password-dialog"
      :options="{
        size: 360,

        header: {
          title: 'Nulstil adgangskode',
          closeButton: {
            show: true,
            onClick: () => store.hide(),
          },
        },

        backdrop: {
          show: true,
          onClick: () => store.hide(),
        },
      }"
    >
      <LoadingSpinner v-if="loading" />

      <template v-else>
        <form v-if="!done" @submit.prevent="submit">
          <Error v-if="error" :error="error" />

          <TextField
            ref="emailField"
            label="E-mail"
            required
            name="email"
            type="email"
            autocomplete="email"
            v-model="model.email.value"
            :errorText="model.email.error"
            @change="validateEmail()"
          />

          <div class="footer">
            <Button
              theme="peach"
              :disabled="hasErrors"
              label="Fortsæt"
              type="submit"
            />
          </div>
        </form>

        <div v-else class="done-text">
          <p>Hvis e-mailen <strong>{{ model.email.value }}</strong> er registreret, har vi sendt dig en e-mail som du skal bruge for at nulstille din adgangskode.</p>
        </div>
      </template>
    </Dialog>
  </transition>
</template>

<script lang="ts">
import { Vue, Component, Ref, Watch } from 'vue-property-decorator'
import Dialog from 'vue-lasso-core/components/Dialog.vue'
import LoadingSpinner from 'vue-lasso-core/components/LoadingSpinner.vue'
import Error from '../LoginDialogTemplate/parts/Error.vue'
import TextField from '@/components/new-fields/TextField.vue'
import Button from '../Button.vue'
import store from './store'
import { validateEmail } from '../LoginDialogTemplate/modules/validation'
import userApi from '@/modules/users/api'

@Component({
  components: {
    Dialog,
    LoadingSpinner,
    Error,
    TextField,
    Button,
  },
})

export default class ForgotPasswordDialog extends Vue {
  @Ref()
  readonly emailField!: TextField

  store = store

  loading = false

  done = false

  error = ''

  model = {
    email: {
      value: '',
      error: '',
    },
  }

  get hasErrors () {
    for (const field of Object.values(this.model)) {
      if (!!field.error) return true
    }

    return false
  }

  validateEmail () {
    this.model.email.error = validateEmail(this.model.email.value).error ?? ''
  }

  validate () {
    this.validateEmail()
  }

  submit () {
    this.validate()

    if (this.hasErrors) return

    this.loading = true

    userApi.requestPasswordReset(this.model.email.value).then(response => {
      if (!response.ok) {
        console.error('Request password reset failed. Data:', response.data)
        this.error = 'Noget gik galt'
        this.loading = false
        return
      }

      this.loading = false
      this.done = true
    })
  }

  @Watch('store.state.show')
  onShowChanged () {
    if (this.loading) return

    this.loading = false
    this.done = false
    this.error = ''
    this.model.email.value = ''
    this.model.email.error = ''
  }
}
</script>

<style lang="scss" scoped>
@import '@shared/style/global.scss';

@mixin deep { ::v-deep { @content } }

.forgot-password-dialog {
  &.v-enter-active {
    @include deep {
      & > .backdrop {
        transition: opacity 150ms linear;
        opacity: 0;
      }

      & > .dialog {
        transition: opacity 45ms linear, transform 150ms ease-out;
        opacity: 0;
        transform: scale(0.8);
      }
    }

    &.v-enter-to {
      @include deep {
        & > .backdrop {
          opacity: 1;
        }

        & > .dialog {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }

  &.v-leave-active {
    transition: opacity 75ms linear;
    opacity: 1;

    &.v-leave-to {
      opacity: 0;
    }
  }
}

.forgot-password-dialog {
  z-index: 999999;

  .loading-spinner {
    margin: auto;
  }

  .error {
    margin-bottom: 24px;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
  }

  .done-text {
    text-align: center;

    p {
      margin: -4px 0;

      & + p {
        margin-top: 8px - 4px;
      }
    }

    strong {
      font-weight: 500;
    }
  }
}
</style>
