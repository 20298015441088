import userStore from '@/modules/users/store'
import Vue from 'vue'

const state = Vue.observable({
  show: false,
})

const forgotPasswordDialogStore = {
  state,

  show () {
    if (userStore.user) return

    state.show = true
  },

  hide () {
    state.show = false
  },
}

export default forgotPasswordDialogStore

userStore.$watch(() => userStore.user, user => {
  if (user) forgotPasswordDialogStore.hide()
})
