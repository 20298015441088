import { orderBy, keys } from 'lodash'

class Search {
  results = []
  statusFilter = null
  statusFiltersFilter = []
  entityType = null
  entityTypes = []
  loading = false
  loadingNextPage = false
  query = ''
  getTags = true
  tagCache = {}
  noMoreResults = false
  // the API service provided must contain methods get and post for making queries
  api = null

  constructor(statusFilters, entityTypes, api, getTags = true, statusFilter = 'active', entityType = 'all') {
    if (!api) throw new Error('An API service must be provided, e.g. Vue-resource or Axios')
    this.api = api
    this.statusFilters = statusFilters
    this.entityTypes = entityTypes
    this.statusFilter = statusFilter
    this.entityType = entityType
    this.getTags = getTags
  }

  search (q, pageSize, getNextPage, entityType) {
    if (q === '') return
    this.loading = true
    this.loadingNextPage = getNextPage == true
    this.query = q
    if (entityType) this.entityType = entityType
    let searchConfig = {
      params: {
        query: q.trim(),
        cToken: getNextPage ? this.continuationToken : null,
        type: this.entityType.toLowerCase(),
        status: this.statusFilter,
        pageSize: pageSize
      }
    }

    return new Promise((resolve) => {
      this.api.get('virk/search', searchConfig).then(response => {
        if(q !== this.query) return
        this.noMoreResults = this.entityType === 'all'
          ? !response.data.companies.hasNextPage && !response.data.people.hasNextPage
          : this.entityType === 'company'
            ? !response.data.companies.hasNextPage
            : !response.data.people.hasNextPage
        this.continuationTokenSave(response.data)
        let tempResults = this.entityType === 'all'
        ? response.data.companies.results.concat(response.data.people.results)
        : this.entityType === 'person'
        ? response.data.people.results
        : response.data.companies.results
        if (tempResults.length < 6 && this.continuationToken) this.search(this.query, 6, false)
        else {
          if (this.getTags) tempResults.forEach(result => { result.tags = { checking: false, tags: [] } }) // has to be here, so that Vue can respond to changes// has to be here, so that Vue can respond to changes
          if (!getNextPage) this.results.length = 0
          this.results = getNextPage ? this.results.concat(this.sortByScore(tempResults)) : this.sortByScore(tempResults)
        }
        if (this.results.length > 0 && this.getTags) {
          this.results = this.results.map(r => {
            let newResult  = { ...r, tags: [] }
            newResult.tags.checking = false
            return newResult
          })
          this.getResultTags(this.results)
        }
        this.loading = this.loadingNextPage = false
        resolve(this.results)
      })
    })
  }

  searchNextPage () {
    if (this.continuationToken && !this.loading) this.search(this.query, 3, true)
  }

  getResultTags(results) {
    let check = []
    results.forEach(result => {
      let current = this.tagCache[result.lassoId]
      if (!current) {
        result.tags.checking = true
        check.push(result.lassoId)
      } else if (!current.checking && current.length) {
        result.tags.splice(0, result.tags.length, ...current)
        result.tags.checking = false
      }
    })
    if (check.length) {
      this.api.post('tags/lookup', check).then(checks => {
        if (!keys(checks.data).length) {
          check.forEach(k => {
            this.tagCache[k.toUpperCase()] = []
          })
        } else {
          keys(checks.data).forEach(k => {
            this.tagCache[k.toUpperCase()] = checks.data[k] || []
          })
        }
        results.forEach(result => {
          result.tags.splice(0, result.tags.length, ...this.tagCache[result.lassoId] || [])
          result.tags.checking = false
        })
      }, () => { })
    }
  }

  clearResults(query) {
    this.noMoreResults = false
    this.query = query
    this.results.length = 0
    this.tagCache = {}
  }

  changeStatusFilter(statusFilter) {
    this.statusFilter = statusFilter
    this.search(this.query, 3, false)
  }

  changeEntityType(entityType) {
    this.entityType = entityType
    this.search(this.query, entityType === 'all' ? 3 : 6, false)
  }

  sortByScore (results) {
    return orderBy(results, ['score'], ['desc'])
  }

  continuationTokenSave(data) {
    this.continuationToken =
      this.entityType === 'all'
        ? data.companies.continuationToken || data.people.continuationToken
        : this.entityType === 'company'
          ? data.companies.continuationToken
          : data.people.continuationToken
  }
}

export { Search as default }
