export function validateEmail (email: string) {
  return !!email && (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(email)
    ? { valid: true }
    : { valid: false, error: 'Ugyldig e-mail' }
}

export function validatePassword (password: string) {
  return password.length >= 6
    ? { valid: true }
    : { valid: false, error: 'Adgangskoden skal være mindst 6 tegn' }
}

export function validateConfirmPassword (password1: string, password2: string) {
  return password1 == password2
    ? { valid: true }
    : { valid: false, error: 'Adgangskoderne er ikke ens' }
}
