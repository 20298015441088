import Vue from 'vue'

type Status = 'submitted' | 'dismissed' | null

const storage = {
  status: {
    key: 'lasso.dk/feedback-dialog/status',
    get: () => {
      try {
        const status = localStorage.getItem(storage.status.key)

        if (
          status === 'submitted'
          || status === 'dismissed'
        ) return status
      } catch (err) {}

      return null
    },
    set: (status: Status) => {
      try {
        if (status) localStorage.setItem(storage.status.key, status)
        else localStorage.removeItem(storage.status.key)
      } catch (err) {}
    },
  },
}

const state = Vue.observable({
  showDialog: false,
})

const feedbackDialogModule = {
  storage,

  state,
}

export default feedbackDialogModule
