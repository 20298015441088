<template>
  <div
    :class="[
      'header',
      isSmall && 'header--small',
    ]"
  >
    <IconButton
      icon="arrow_back"
      :size="isSmall ? 'small' : 'normal'"
      :style="{ visibility: router.canGoBack ? '' : 'hidden' }"
      @click="() => router.goBack()"
    />

    <div v-if="title" class="title">
      <h1>{{ title }}</h1>
    </div>

    <IconButton
      icon="close"
      :size="isSmall ? 'small' : 'normal'"
      @click="() => userStore.hideLoginDialog({ dismissed: true })"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, InjectReactive } from 'vue-property-decorator'
import IconButton from 'vue-lasso-core/components/IconButton.vue'
import userStore from '@/modules/users/store'
import router from '../router/router'

@Component({
  components: {
    IconButton,
  },
})

export default class Header extends Vue {
  @Prop({ type: String, required: false })
  readonly title?: string

  @InjectReactive()
  readonly size!: number

  userStore = userStore
  router = router

  get isSmall () { return this.size < 900 }
}
</script>

<style lang="scss" scoped>
@import '@shared/style/global.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
  padding: 24px;
  margin: -24px;
  margin-bottom: 0;

  & > .icon-button {
    margin: -6px;
  }

  .title {
    @include typography('title');
    margin: 0 32px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--small > .icon-button {
    padding: 7px;
    margin: -8px -7px;
  }

  &--small .title {
    @include typography('headline');
    margin-top: -4px;
    margin-bottom: -4px;
  }
}
</style>
