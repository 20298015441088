export default class FollowedEntities {
  byLassoId: Record<string, FollowedEntity> = {}
  companies: FollowedEntity[] = []
  people: FollowedEntity[] = []

  add: (entities: FollowedEntity[]) => void
  remove: (lassoIds: string[]) => void

  constructor (entities?: FollowedEntity[]) {
    const update = () => {
      this.byLassoId = Object.assign({}, this.byLassoId)
      this.companies = []
      this.people = []

      for (const lassoId in this.byLassoId) {
        const array = lassoId.startsWith('CVR-1-')
          ? this.companies
          : this.people

        array.push(this.byLassoId[lassoId])
      }
    }

    this.add = entities => {
      for (const entity of entities) {
        const existingEntity = this.byLassoId[entity.lassoId]
        if (existingEntity) {
          existingEntity.name = entity.name
          continue
        }

        this.byLassoId[entity.lassoId] = entity
      }

      update()
    }

    this.remove = lassoIds => {
      for (const lassoId of lassoIds) {
        delete this.byLassoId[lassoId]
      }

      update()
    }

    if (entities) this.add(entities)
  }

  clone () {
    const followedEntities = new FollowedEntities()
    followedEntities.byLassoId = { ...this.byLassoId }
    followedEntities.companies = this.companies.slice()
    followedEntities.people = this.people.slice()
    return followedEntities
  }
}

export interface EntityReference {
  lassoId: string
  name: string
}

export class FollowedEntity {
  lassoId: string
  name: string
  followDate: Date

  constructor (response: ResponseFollowedEntity) {
    this.lassoId = response.lassoId
    this.name = response.name
    this.followDate = new Date(response.added)
  }
}

export interface ResponseFollowedEntity {
  lassoId: string
  name: string
  added: string | Date
}
