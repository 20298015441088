<template>
  <LoginDialogTemplate
    v-if="show"
    class="login-to-follow-dialog"
    :options="{
      ...options,
      redirectUrl,
      onLoggedIn: onLoggedIn,
    }"
  >
    <template v-slot:intro-super-title>Hold dig opdateret på</template>

    <template v-slot:intro-title>{{ entity.name }}</template>

    <template v-slot:intro-text>
      <p>Lasso overvåger alt fra adresseskift til konkurser og årsregnskaber. Med en Lasso.dk profil får du besked, når det sker! Du får også adgang til kontaktoplysninger på personer, flere nøgletal og meget mere.</p>
      <p>Det bedste af det hele? Det er gratis.</p>
    </template>
  </LoginDialogTemplate>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import LoginDialogTemplate from '@/components/LoginDialogTemplate/LoginDialogTemplate.vue'
import userStore from '@/modules/users/store'
import { lassoxPortalUrl } from '@/lasso-urls'

@Component({
  components: {
    LoginDialogTemplate,
  },
})

export default class LoginToFollowDialog extends Vue {
  get show () {
    return !!userStore.loginToFollowDialogOptions
  }
  
  get options () {
    return userStore.loginToFollowDialogOptions
  }

  get entity () {
    return userStore.loginToFollowDialogOptions?.entity
  }

  get redirectUrl () {
    if (!this.entity) return null
    return `${lassoxPortalUrl}/${this.entity.lassoId}`
  }

  async onLoggedIn () {
    const user = userStore.user
    const entity = this.entity

    if (!user || !entity) return

    // TODO
    // await user.followEntities([ entity ])
  }
}
</script>
