import { EventBus } from '@/assets/js/helpers'

/**
 * Tracks a goal using Plausible, e.g. how often a specific button is clicked. Bottom of https://plausible.io/docs/custom-event-goals
 * @param goalName - A goal with the same name has to manually be created in plausible
 * @param config.callback - Should be used when navigating, as the goal will not be tracked if the user navigates away before the request is complete.
 * @param config.properties - Can be used in Plausible to "sub-divide" the goal, seeing how often it was triggered with a specific property.
*/
export const trackGoal = (goalName: string, config: { props?: Record<string, string>, callback?: () => void}) => {
  EventBus.$emit('trackGoal', {goalName, config})
}