import Vue from 'vue'
import User from './User'
import api from './api'
import { trackEvent } from '@/utils/googleAnalytics'
import LoginDialogTemplateOptions from '@/components/LoginDialogTemplate/options'
import { EntityReference } from './FollowedEntities'
import { lassoxPortalUrl } from '@/lasso-urls'

interface State {
  // TODO: Signup, rather than login, is actually experimenetally the initial/primary view of this dialog ATM. A renaming of all these 'logins' might be appropriate if that decision sticks
  loginDialogOptions: LoginDialogOptions | null
  loginToFollowDialogOptions: LoginToFollowDialogOptions | null
  loginToShowCompanyDialogOptions: LoginToShowCompanyDialogOptions | null
  loginToShowContactDialogOptions: LoginToShowContactDialogOptions | null
  loginToShowKeyfiguresDialogOptions: LoginToShowKeyfiguresDialogOptions | null

  user: User | null
  loadUserPromise: Promise<User | null> | null
}

const userStore = new Vue({
  data: (): State => ({
    loginDialogOptions: null,
    loginToFollowDialogOptions: null,
    loginToShowCompanyDialogOptions: null,
    loginToShowContactDialogOptions: null,
    loginToShowKeyfiguresDialogOptions: null,

    user: null,
    loadUserPromise: null,
  }),

  methods: {
    showLoginDialog (options: LoginDialogOptions) {
      this.hideLoginDialog()
      this.loginDialogOptions = options
    },

    showLoginToShowCompanyDialog (options: LoginToShowCompanyDialogOptions) {
      this.hideLoginDialog()

      const redirectUrl = `${lassoxPortalUrl}/${options.lassoId}`
      if (this.user) {
        location.href = redirectUrl
        return
      }

      this.loginToShowCompanyDialogOptions = options
    },

    showLoginToFollowDialog (options: LoginToFollowDialogOptions) {
      this.hideLoginDialog()
      this.loginToFollowDialogOptions = options
    },

    showLoginToShowContactDialog (options: LoginToShowContactDialogOptions) {
      this.hideLoginDialog()
      this.loginToShowContactDialogOptions = options
    },

    showLoginToShowKeyfiguresDialog (options: LoginToShowKeyfiguresDialogOptions) {
      this.hideLoginDialog()
      this.loginToShowKeyfiguresDialogOptions = options
    },

    hideLoginDialog (options: { dismissed?: boolean } = {}) {
      for (const key of [
        'loginDialogOptions',
        'loginToFollowDialogOptions',
        'loginToShowCompanyDialogOptions',
        'loginToShowContactDialogOptions',
        'loginToShowKeyfiguresDialogOptions',
      ] as const) {
        const dialogOptions = this[key]
        if (!dialogOptions) continue

        if (options.dismissed) dialogOptions.onDismiss?.()

        this[key] = null
      }

      this.loginDialogOptions = null
      this.loginToFollowDialogOptions = null
      this.loginToShowCompanyDialogOptions = null
      this.loginToShowContactDialogOptions = null
      this.loginToShowKeyfiguresDialogOptions = null
    },

    unsetUser () {
      this.user = null
      this.loadUserPromise = null
    },

    async loadUser () {
      const promise = api.fetchUserInfo()
      this.loadUserPromise = promise

      const user = await promise

      if (promise !== this.loadUserPromise) {
        return await this.loadUserPromise
      }

      this.user = user
      this.loadUserPromise = null
    },

    // async logout () {
    //   this.unsetUser()
    //   await api.logout()
    //   trackEvent({
    //     eventCategory: 'Click',
    //     eventAction: 'Log ud',
    //     eventLabel: document.location.pathname,
    //   })
    // },
  },
})

export default userStore

export const creditsafeIntro = { 
  lassoxIntro: {
    title: 'Gratis kreditvurdering',
    text: 'Opret en bruger nu og få adgang til kreditvurderinger af alle danske firmaer – helt gratis.',
    image: require('@/assets/img/signup-dialog-creditsafe-phone.png'),
    color: 'purple' 
  }
}

export interface LoginDialogOptions extends LoginDialogTemplateOptions {}

export interface LoginToFollowDialogOptions extends LoginDialogTemplateOptions {
  entity: EntityReference
}
export interface LoginToShowCompanyDialogOptions extends LoginDialogTemplateOptions {
  lassoId: string
}

export interface LoginToShowContactDialogOptions extends LoginDialogTemplateOptions {}

export interface LoginToShowKeyfiguresDialogOptions extends LoginDialogTemplateOptions {
  lassoId: string
}