var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('transition',{attrs:{"duration":{ enter: 150, leave: 75 }}},[(_vm.store.state.show)?_c('Dialog',{staticClass:"forgot-password-dialog",attrs:{"options":{
      size: 360,

      header: {
        title: 'Nulstil adgangskode',
        closeButton: {
          show: true,
          onClick: () => _vm.store.hide(),
        },
      },

      backdrop: {
        show: true,
        onClick: () => _vm.store.hide(),
      },
    }}},[(_vm.loading)?_c('LoadingSpinner'):[(!_vm.done)?_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[(_vm.error)?_c('Error',{attrs:{"error":_vm.error}}):_vm._e(),_c('TextField',{ref:"emailField",attrs:{"label":"E-mail","required":"","name":"email","type":"email","autocomplete":"email","errorText":_vm.model.email.error},on:{"change":function($event){return _vm.validateEmail()}},model:{value:(_vm.model.email.value),callback:function ($$v) {_vm.$set(_vm.model.email, "value", $$v)},expression:"model.email.value"}}),_c('div',{staticClass:"footer"},[_c('Button',{attrs:{"theme":"peach","disabled":_vm.hasErrors,"label":"Fortsæt","type":"submit"}})],1)],1):_c('div',{staticClass:"done-text"},[_c('p',[_vm._v("Hvis e-mailen "),_c('strong',[_vm._v(_vm._s(_vm.model.email.value))]),_vm._v(" er registreret, har vi sendt dig en e-mail som du skal bruge for at nulstille din adgangskode.")])])]],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }