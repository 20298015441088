var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'text-field',
    _vm.leadingIcon && 'has-leading-icon',
    (_vm.trailingIcon || _vm.errorText) && 'has-trailing-icon',
    _vm.focused && 'is-focused',
    _vm.value && 'has-value',
    _vm.errorText && 'has-error',
  ]},[_c('div',{staticClass:"field-container"},[_c('div',{staticClass:"outline"}),_c('div',{staticClass:"floating-label"},[_c('div',{staticClass:"floating-label-text"},[_c('span',[_vm._v(_vm._s(_vm.label))]),(_vm.required)?_c('span',{staticClass:"required-asterisk"},[_vm._v(" *")]):_vm._e()])]),_c('input',{ref:"inputEl",staticClass:"input",attrs:{"name":_vm.name,"type":_vm.type,"autocomplete":_vm.autocomplete},domProps:{"value":_vm.value},on:{"mousedown":function($event){return _vm.$emit('mousedown', $event)},"focus":event => {
        _vm.focused = true
        _vm.$emit('focus', event)
      },"blur":event => {
        _vm.focused = false
        _vm.$emit('blur', event)
      },"keydown":function($event){return _vm.$emit('keydown', $event)},"input":function($event){return _vm.$emit('input', $event.target.value)},"change":function($event){return _vm.$emit('change', $event.target.value)}}}),(_vm.leadingIcon)?_c('div',{staticClass:"leading-icon"},[_c('Icon',{attrs:{"icon":_vm.leadingIcon,"size":"small"}})],1):_vm._e(),(_vm.trailingIcon || _vm.errorText)?_c('div',{staticClass:"trailing-icon"},[(_vm.trailingIcon)?[(!_vm.onClickTrailingIcon)?_c('Icon',{attrs:{"icon":_vm.trailingIcon,"size":"small"}}):_c('IconButton',{attrs:{"icon":_vm.trailingIcon,"size":"small","type":"button"},on:{"click":() => _vm.onClickTrailingIcon()}})]:_c('Icon',{staticClass:"error-icon",attrs:{"icon":"error","size":"small"}})],2):_vm._e()]),(!_vm.errorText && _vm.helperText)?_c('p',{staticClass:"helper-text"},[_vm._v(_vm._s(_vm.helperText))]):_vm._e(),(_vm.errorText)?_c('p',{staticClass:"error-text"},[_vm._v(_vm._s(_vm.errorText))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }