<template>
  <component
    :is="href ? 'a' : 'button'"
    v-bind="attrs"
    @click="event => !disabled && $emit('click', event)"
  >
    <div v-if="outlined" class="button-outline" />

    <slot>
      <Icon v-if="icon" :icon="icon" size="small" />
      <div class="label">{{ label }}</div>
    </slot>
  </component>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import Icon from 'vue-lasso-core/components/Icon.vue'

@Component({
  components: {
    Icon,
  },
})

export default class Button extends Vue {
  @Prop({ type: String, default: 'normal' })
  readonly size!: string

  @Prop({ type: String, default: 'transparent' })
  readonly theme!: string

  @Prop(Boolean)
  readonly outlined!: boolean

  @Prop(Boolean)
  readonly disabled!: boolean

  @Prop({ type: String, required: false })
  readonly icon?: string

  @Prop({ type: String, default: '' })
  readonly label!: string

  @Prop({ type: String })
  readonly href?: string

  get attrs () {
    const vm = this

    const attrs: Record<string, unknown> = {
      get class () {
        return [
          'button',
          `button--size--${vm.size}`,
          `button--theme--${vm.theme}`,
          vm.disabled && 'is-disabled',
        ]
      },
      get disabled () { return vm.disabled },
    }

    if (this.href) {
      attrs.href = this.href
    } else {
      attrs.type = 'button'
    }

    return attrs
  }
}
</script>

<style lang="scss" scoped>
@import '@shared/style/global.scss';

.button {
  @include typography('button');
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  text-decoration: none;
  transition-property: color, background-color;
  transition-duration: 100ms;

  &:hover {
    text-decoration: none;
  }

  & > .button-outline {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
  }

  & > .md-icon {
    margin-left: -4px;
    margin-right: 8px;
    color: inherit;
  }
}

.button--size--small {
  height: 24px;
  padding: 0 12px;
}

.button--size--normal {
  min-width: 64px;
  height: 32px;
  padding: 0 20px;
}

@mixin theme (
  $fgColor,
  $bgColor,

  $outlineColor: null,

  $disabledFgColor: null,
  $disabledBgColor: null,

  $overlay,
) {
  color: $fgColor;
  background-color: $bgColor;

  @if $outlineColor {
    & > .button-outline {
      box-shadow: inset 0 0 0 1px $outlineColor;
    }
  }

  &.is-disabled {
    color: $disabledFgColor;
    background-color: $disabledBgColor;
  }

  &:not(.is-disabled) {
    $overlayColor: map-get($overlay, 'color');

    &:hover {
      background-color: mix($overlayColor, $bgColor, map-get($overlay, 'hover'));
    }

    &:focus {
      background-color: mix($overlayColor, $bgColor, map-get($overlay, 'focused'));
    }

    &:active {
      background-color: mix($overlayColor, $bgColor, map-get($overlay, 'pressed'));
    }
  }
}

.button--theme--transparent {
  @include theme(
    $fgColor: #616161,
    $bgColor: transparent,

    $outlineColor: #B7B7B7,

    $disabledFgColor: rgba(black, 0.4),

    $overlay: (
      'color': #B7B7B7,
      'hover': 20%,
      'focused': 50%,
      'pressed': 80%,
    ),
  );
}

.button--theme--white {
  @include theme(
    $fgColor: inherit,
    $bgColor: white,

    $outlineColor: #B7B7B7,

    $overlay: (
      'color': #B7B7B7,
      'hover': 20%,
      'focused': 50%,
      'pressed': 80%,
    ),
  );
}

.button--theme--pink,
.button--theme--red {
  @include theme(
    $fgColor: white,
    $bgColor: #ff8091,

    // $disabledFgColor: rgba(black, 0.6),
    // $disabledBgColor: rgba(black, 0.12),

    $overlay: (
      'color': #ff3d57,
      'hover': 20%,
      'focused': 50%,
      'pressed': 80%,
    ),
  );
}

.button--theme--peach {
  font-weight: 500;
  @include theme(
    $fgColor: white,
    $bgColor: #FF8E61,

    // $disabledFgColor: rgba(black, 0.6),
    // $disabledBgColor: rgba(black, 0.12),

    $overlay: (
      'color': #FF7138,
      'hover': 20%,
      'focused': 50%,
      'pressed': 80%,
    ),
  );
}
</style>
