<template>
	<div id="TopBanners">
		<template v-if="isFirstPage">
			<Banner bannerType="bm-dsk_top"></Banner>
			<Banner bannerType="bm-mob_top"></Banner>
		</template>
		<Banner
			v-if="windowSize.width > 1520"
			bannerType="bm-lb1"
		></Banner>
		<Banner v-else bannerType="bm-lb2"></Banner>
	</div>
</template>

<script>
import { EventBus } from "@/assets/js/helpers";
import Banner from "./Banner.vue";
import windowSize from '@/window-size';
export default {
	data() {
		return {
      windowSize,
			isFirstPage: true,
		};
	},
	created() {
		EventBus.$on("loading", (routeTo) => {
			this.isFirstPage = false;
		});
	},
	components: {
		Banner,
	},
};
</script>

<style lang="less">
#app {
	#TopBanners {
		margin: 15px 0;
	}
	#TopBanners .banner.filled {
		// margin-top: 15px;
	}

	&.min-sm {
		 #TopBanners {
			margin: 30px 0;
			.banner.filled {
				// margin-top: 30px;
			}
		}
	}
}

</style>
