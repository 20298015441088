import { render, staticRenderFns } from "./FeedbackDialog.vue?vue&type=template&id=6de6f98c&scoped=true"
import script from "./FeedbackDialog.vue?vue&type=script&lang=ts"
export * from "./FeedbackDialog.vue?vue&type=script&lang=ts"
import style0 from "./FeedbackDialog.vue?vue&type=style&index=0&id=6de6f98c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../Lasso.LassoDK/app/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6de6f98c",
  null
  
)

export default component.exports