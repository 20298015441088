function tryOr<T, FT> (callback: () => T, fallback: (exception: unknown) => FT) {
  try {
    return callback()
  } catch (exception) {
    return fallback(exception)
  }
}

function get (key: string) {
  return tryOr(
    () => localStorage.getItem(key),
    () => null,
  )
}

function set (key: string, value: string) {
  try {
    localStorage.setItem(key, value)
  } catch {
    // ...
  }
}

function remove (key: string) {
  try {
    localStorage.removeItem(key)
  } catch {
    // ...
  }
}

function clear () {
  try {
    localStorage.clear()
  } catch {
    // ...
  }
}

function getJSON (key: string) {
  const json = get(key)
  if (!json) return null
  return tryOr(
    () => JSON.parse(json),
    () => null,
  )
}

function setJSON (key: string, value: unknown) {
  try {
    const json = JSON.stringify(value)
    set(key, json)
  } catch {
    // ...
  }
}

const localStorageUtils = {
  get,
  set,
  remove,
  clear,
  getJSON,
  setJSON,
}

export default localStorageUtils
