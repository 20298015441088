<template>
  <div id="app-header" :class="{ collapsed }">
    <div class="width-constraint">
      <div class="header-content d-flex align-items-center">
        <div class="logo-and-lassox-link">
          <router-link exact :to="{ name: 'home' }">
            <img src="../assets/img/logo.png" />
          </router-link>
        </div>

        <div class="search flex-grow-1" :class="{ focused: searchInputFocus }">
          <search-bar
            ref="searchbar"
            class="plausible-event-name=search+bar+click"
            :EventBus="EventBus"
            :query="search.query"
            :entity-types="search.entityTypes"
            :entity-type="search.entityType"
            :status-filters="search.statusFilters"
            :status-filter="search.statusFilter"
            :loading="search.loading"
            :loading-next-page="search.loading && search.loadingNextPage"
            :results="search.results"
            :noMoreResults="search.noMoreResults"
            :focus-on-search-bar="searchInputFocus"
            :is-narrow="isNarrow"
            @query-changed="query => onQueryChanged(query)"
            @entity-type-changed="entityType => search.changeEntityType(entityType)"
            @status-changed="status => search.changeStatusFilter(status)"
            @entity-selected="entity => onEntitySelected(entity)"
            @scrolled-to-bottom="() => search.searchNextPage()"
            @search-bar-focused="focus => searchInputFocus = focus"
          />
        </div>

        <div>
          <Button
            class="open-platform-button"
            :class="[!userStore.user && 'plausible-event-name=top+nav+signup']"
            theme="peach"
            :href="userStore.user ? platformUrl : undefined"
            @click="e => !userStore.user && onClickLoginButton(e)"
          >
            <div class="open-platform-button-overlay" />

            <div class="open-platform-button-logo" />

            <div
              class="open-platform-button-label"
              v-text="userStore.user ? 'Åbn platform' : 'Opret gratis'"
            />
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchBar from '@oldShared/lasso/vue-components/SearchBar/SearchBar.vue'
import Button from '@/components/Button.vue'
import Icon from 'vue-lasso-core/components/Icon.vue'
import Search from '@oldShared/lasso/vue-components/SearchBar/assets/js/searchBar.js'
import { EventBus } from '@/assets/js/helpers.js'
import debounce from 'lodash/debounce'
import userStore from '@/modules/users/store'
import windowSize from '@/window-size'
import { lassoxPortalUrl } from '@/lasso-urls'

export default {
  name: 'appHeader',

  components: {
    SearchBar,
    Button,
    Icon,
  },

  data () {
    return {
      search: null,
      searchInputFocus: false,
      EventBus: EventBus,
      trackSearchWithDebounce: debounce(query => this.trackSearch(query), 2000),
      lastScrollLocation: 0,
      collapsed: false,
      userStore,
      platformUrl: lassoxPortalUrl,
    }
  },

  computed: {
    isNarrow () {
      return windowSize.width < windowSize.sizes.xs
    },
  },

  methods: {
    onQueryChanged (query) {
      if (query.length > 0) {
        this.search.search(query, 3, false)
        this.trackSearchWithDebounce(query)
      }
      else this.search.clearResults('')
    },

    onEntitySelected (entity) {
      this.$router.push({name: entity.entityType.toLowerCase(), params: { id: entity.lassoId.substring(6), name: entity.name.toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '-') }})
      // track query immediately (no debounce) before tracking the result click
      this.trackSearchWithDebounce.cancel()
      this.trackSearch(this.search.query)
      this.$root.trackEvent({ eventCategory: 'Click', eventAction: 'Søgeresultat (' + (entity.entityType === 'Company' ? 'firma' : 'person') + ')', eventLabel: entity.lassoId })
      this.searchInputFocus = false
      // this.$store.commit('addEntity', entity)
      // this.$router.push({name: 'entity', params: { lassoId: entity.lassoId }})
    },

    trackSearch (query) {
      this.$root.trackEvent({eventCategory: 'Search', eventAction: this.search.entityType, eventLabel: query })
    },

    redirect () {
      this.$root.trackEvent({eventCategory: 'Click', eventAction: 'Se Lasso løsninger', eventLabel: this.$route.fullPath})
      this.$nextTick(() => window.location.href = "http://lassox.com")
    },

    onClickLoginButton () {
      userStore.showLoginDialog({trackingEventName: 'top+nav+signup'})
    },
  },

  watch: {
    lastScrollLocation (now, before) {
      this.collapsed = windowSize.width < windowSize.sizes.sm && now !== 0 && now > before
    }
  },

  created () {
    this.search = new Search(['all', 'active'], ['all', 'company', 'person'], this.$http, false)
    EventBus.$on('scrolled', () => this.lastScrollLocation = window.scrollY || document.body.scrollTop || document.documentElement.scrollTop)
  },
}
</script>

<style lang="scss" scoped>
@import '@shared/style/global.scss';

.open-platform-button {
  height: 40px;
  padding: 0 20px;
  border-radius: 20px;
  background-image: linear-gradient(-45deg, #FF8091 0%, #FFA07A 100%);

  .open-platform-button-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(-45deg, #FF3D57 0%, #FF7138 100%);
    opacity: 0;
    transition: opacity 100ms;
  }

  .open-platform-button-logo {
    position: relative;
    width: 16px;
    height: 16px;
    margin-left: -4px;
    margin-right: 8px;
    background-image: url('~@/assets/img/lasso-x-only-x-logo-white.svg?inline');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .open-platform-button-label {
    position: relative;
  }

  &:hover .open-platform-button-overlay {
    opacity: 0.2;
  }

  &:focus .open-platform-button-overlay {
    opacity: 0.5;
  }

  &:active .open-platform-button-overlay {
    opacity: 0.8;
  }

  &:is(#app.min-sm .open-platform-button) {
    height: 36px;
  }
}
</style>

<style lang="less">
@import (reference) "~@oldShared/lasso/style/constants/colors";
#app-header {
  background-color: #fff;
  width: 100%;
  // height: 80px;
  position: fixed;
  top: 0;
  // &.collapsed {
  //   top: -55px;
  // }
  z-index: 99;
  box-shadow: 0 2px 5px rgba(0,0,0,.1);

  .width-constraint {
    margin: 0 auto;
  }

  .logo-and-lassox-link {
    font-size: 0;
    line-height: 0;
    transform: translateY(-4.5px);

    a {
      display: block;

      img {
        height: 26px;
      }
    }
  }

  .btn-lassox {
    background-color: #FFC914;
    border-color: #FFC914;
    color: #3D464A;
    font-size: 15px;
    font-weight: 700;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 24px;
    height: 40px;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  .search {
    transition: margin-left .5s;
    &.focused {
      margin-left: -102px;
    }
    padding: 15px;
    #searchBar {
      position: inherit !important;
      #search-input {
        height: 40px;
        border-radius: 30px;
        transition: border-radius .1s ease-in-out;
        // line-height: 40px;
        background-color: #f1f1f1;
        .search-field {
          border-radius: 30px;
          background: #f1f1f1;
          height: 40px;
        }
      }

      #results-dropdown {
        box-shadow: 0 5px 10px 1px rgba(50,50,50,.1) !important;
        border-radius: 0 0 8px 8px;
        overflow: hidden;
      }
    }
  }

  #app.xxs & {
    padding: 0 15px !important;
    .btn-lassox {
      display: none;
    }
  }

  #app.xs & {
    .logo-and-lassox-link img {
      height: 26px;
    }
  }

  #app.min-sm & {
    .header-content {
      height: 80px;
      .logo-and-lassox-link {
        padding-top: 0;
        img {
          height: 26px;
        }
      }

      .search {
        padding: 0 24px;

        &.focused {
          margin-left: 0;
        }

        #searchBar {
          position: relative !important;
          #search-input {
            height: 36px;
            // line-height: 36px;
            &.has-query.focused {
              border-radius: 8px 8px 0 0;
            }
            .search-field {
              height: 36px;
            }
          }
        }
      }

      .btn-lassox {
        height: 36px;
      }
    }
  }
}
</style>
