<template>
  <transition :duration="{ enter: 150, leave: 75 }">
    <Dialog
      v-if="store.state.show"
      class="external-login-welcome-dialog"
      :options="{
        size: 360,

        header: {
          title: 'Tillyke, du er oprettet!',
          closeButton: {
            show: true,
            onClick: () => store.hide(),
          },
        },

        backdrop: {
          show: true,
          onClick: () => store.hide(),
        },
      }"
    >
      <AcceptMarketingField v-model="acceptMarketing" />

      <div class="footer">
        <Button
          class="ml-auto"
          theme="red"
          :disabled="!acceptMarketing"
          label="Fortsæt"
          @click="() => {
            userApi.acceptMarketing()
            store.hide()
          }"
        />
      </div>
    </Dialog>
  </transition>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import Dialog from 'vue-lasso-core/components/Dialog.vue'
import AcceptMarketingField from '@/components/AcceptMarketingField.vue'
import Button from '@/components/Button.vue'
import store from './store'
import userApi from '@/modules/users/api'

@Component({
  components: {
    Dialog,
    AcceptMarketingField,
    Button,
  },
})

export default class ExternalLoginWelcomeDialog extends Vue {
  store = store
  userApi = userApi

  acceptMarketing = false
}
</script>

<style lang="scss" scoped>
@import '@shared/style/global.scss';

@mixin deep { ::v-deep { @content } }

.external-login-welcome-dialog {
  &.v-enter-active {
    @include deep {
      & > .backdrop {
        transition: opacity 150ms linear;
        opacity: 0;
      }

      & > .dialog {
        transition: opacity 45ms linear, transform 150ms ease-out;
        opacity: 0;
        transform: scale(0.8);
      }
    }

    &.v-enter-to {
      @include deep {
        & > .backdrop {
          opacity: 1;
        }

        & > .dialog {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }

  &.v-leave-active {
    transition: opacity 75ms linear;
    opacity: 1;

    &.v-leave-to {
      opacity: 0;
    }
  }
}

.external-login-welcome-dialog {
  z-index: 999999;

  .footer {
    margin-top: 24px;
  }
}
</style>
