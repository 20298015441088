<template>
  <transition :duration="{ enter: 150, leave: 75 }">
    <Dialog
      :class="[
        'login-dialog-template',
        sizes.map(size => `is-size-${size}`),
      ]"
      :options="{
        size: size,

        dense: true,

        scrollable: true,

        backdrop: {
          show: true,
          onClick: () => userStore.hideLoginDialog({ dismissed: true }),
        },
      }"
    >
      <div class="login-dialog-columns">
        <div
          v-for="column in columns"
          :key="column"
          :class="`${column}-column ${options.lassoxIntro ? options.lassoxIntro.color : 'peach'}`"
        >
          <template v-if="column == 'login'">
            <div class="login">
              <component
                v-if="router.current"
                :is="router.current.component"
                v-bind="{
                  options,
                  ...(router.current.props || {}),
                }"
              />
            </div>
          </template>

          <template v-else-if="column == 'intro'">
            <div class="intro-background-cutoff" />

            <div class="intro-content">
              <div class="intro-logo-wrapper">
                <img
                  class="intro-logo"
                  src="@/assets/img/lasso-x-logo-white.svg?inline"
                >
              </div>

              <div class="m-auto" />
                <h1 class="intro-title" v-html="options.lassoxIntro ? options.lassoxIntro.title : 'Få flere muligheder med <br>en gratis profil'"></h1>
                <p class="intro-text" v-html="options.lassoxIntro ? options.lassoxIntro.text : 'Opret en profil og få større indsigt i dansk erhvervsliv. Du får bl.a. adgang til nye værktøjer og flere oplysninger om regnskaber, historik og ejerforhold &ndash; alt sammen helt gratis.'"></p>

                <div class="m-auto" />
                </div>

                <div
                  class="intro-image-wrapper"
                  :class="options.lassoxIntro && options.lassoxIntro.image.includes('signup-dialog-creditsafe-phone') ? 'intro-image-phone' : ''"
                >
                  <img
                    class="intro-image"
                    alt="Et udsnit af mulighederne i Lasso X"
                    :src="options.lassoxIntro ? options.lassoxIntro.image : require('@/assets/img/signup-laptop-platform.png')"
                  >
                </div>
          </template>
        </div>
      </div>
    </Dialog>
  </transition>
</template>

<script lang="ts">
import { Vue, Component, Prop, ProvideReactive } from 'vue-property-decorator'
import Dialog from 'vue-lasso-core/components/Dialog.vue'
import IconButton from 'vue-lasso-core/components/IconButton.vue'
import Button from '@/components/Button.vue'
import Icon from 'vue-lasso-core/components/Icon.vue'
import LoginDialogTemplateOptions from './options'
import userStore from '@/modules/users/store'
import router from './router/router'

const sizes = [ 400, 640, 900 ] as const
type Size = typeof sizes[number]

@Component({
  components: {
    Dialog,
    IconButton,
    Button,
    Icon,
  },
})

export default class LoginDialogTemplate extends Vue {
  @Prop({ type: Object, required: true })
  readonly options!: LoginDialogTemplateOptions

  userStore = userStore
  router = router

  availableWidth = 0

  get sizes () {
    const result: Size[] = [ sizes[0] ]

    for (const size of sizes.slice(1)) {
      if (size > this.availableWidth) break
      result.push(size)
    }

    return result
  }

  @ProvideReactive()
  get size () {
    return this.sizes[this.sizes.length - 1]
  }

  get columns () {
    const columns = [ 'login', 'intro' ]
    if (this.size >= 640) columns.reverse()
    return columns
  }

  beforeCreate () {
    router.reset()
  }

  mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize)
    router.replace(this.options.initialRoute || 'signup')
  }

  onResize () {
    this.availableWidth = this.$el.getBoundingClientRect().width - (32 * 2)
  }

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  }
}
</script>

<style lang="scss" scoped>
@import '@shared/style/global.scss';

@mixin deep { ::v-deep { @content } }

.login-dialog-template {
  &.v-enter-active {
    @include deep {
      & > .backdrop {
        transition: opacity 150ms linear;
        opacity: 0;
      }

      & > .dialog {
        transition: opacity 45ms linear, transform 150ms ease-out;
        opacity: 0;
        transform: scale(0.8);
      }
    }

    &.v-enter-to {
      @include deep {
        & > .backdrop {
          opacity: 1;
        }

        & > .dialog {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }

  &.v-leave-active {
    transition: opacity 75ms linear;
    opacity: 1;

    &.v-leave-to {
      opacity: 0;
    }
  }
}

.login-dialog-template {
  z-index: 999999;

  .login-dialog-columns {
    margin: -16px;

    & > .login-column,
    & > .intro-column {
      min-width: 0;
      width: 100%;
      flex-grow: 0;
      flex-shrink: 1;
    }

    & > .intro-column {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      overflow: hidden;

      &.peach {
        background-image: linear-gradient(147.49deg, #FFA07A 5.91%, #F8D8B9 63.23%);
      }
      &.purple {
        background-image: linear-gradient(147.49deg, #9686F3 0%, #F1E2FA 67.45%);
      }
      &.fuchsia {
        background-image: linear-gradient(147deg, #E373A3 5.91%, #FFE5F0 63.23%);
      }
      &.pink {
        background-image: linear-gradient(147deg, #FF8191 0%, #FFE6E9 65.11%);
      }

      .intro-background-cutoff {
        display: none;
      }

      .intro-content {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 24px;
        flex-grow: 1;
        margin: 0 auto;
        text-align: center;

        .intro-logo-wrapper {
          height: 20px;
          aspect-ratio: 300 / 74;
          margin-top: 2px;
          margin-bottom: 24px + 2px;

          .intro-logo {
            display: block;
            width: 100%;
            height: 100%;
          }
        }

        .intro-title,
        .intro-text {
          color: white;
        }

        .intro-title {
          margin: -4px 0;
          font-size: 17px;
          line-height: 24px;
          font-weight: 500;
        }

        .intro-text {
          margin-top: 24px - 4px;
          margin-bottom: -4px;
          font-size: 14px;
          line-height: 24px;
        }
      }

      .intro-image-wrapper {
        display: none;
      }
    }

    & > .login-column {
      display: flex;
      flex-direction: column;
      // box-shadow: inset 0 -1px 0 0 rgba(black, 0.12);

      & > .login {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
        padding: 24px;
      }
    }
  }

  &.is-size-640 {
    .login-dialog-columns {
      display: flex;
      align-items: stretch;

      & > .intro-column {
        .intro-background-cutoff {
          position: absolute;
          bottom: -350px;
          right: 0;
          display: block;
          width: 200%;
          min-width: 500px;
          height: 500px;
          border-top-right-radius: 250px;
          background-color: #FEFAFA;
          transform-origin: top right;
          transform: rotate(-10deg) translateX(150px);
        }

        .intro-content {
          padding-bottom: 24px;

          .intro-logo-wrapper {
            align-self: flex-start;
          }
        }

        .intro-image-wrapper {
          position: relative;
          align-self: flex-end;
          display: block;
          width: 90%;
          margin-bottom: 24px;
          transform: translateX(15%);
          &.intro-image-phone {
            margin-bottom: -75px;
            width: 100%;
          }

          .intro-image {
            width: 100%;
            height: 100%;
          }
        }
      }

      // & > .login-column {
      //   box-shadow: inset 1px 0 0 0 rgba(black, 0.12);
      // }
    }
  }

  &.is-size-900 {
    .login-dialog-columns {
      & > .intro-column {
        .intro-content {
          .intro-title {
            font-size: 24px;
            line-height: 32px;
          }

          .intro-text {
            font-size: 17px;
            line-height: 24px;
          }
        }
      }
    }
  }
}
</style>
