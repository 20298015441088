var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"loading-spinner",style:({
    width: `${_vm.finalSize}px`,
    height: `${_vm.finalSize}px`,
  })},[_c('svg',{attrs:{"width":_vm.finalSize,"height":_vm.finalSize}},[_c('g',{attrs:{"transform":`rotate(${_vm.rotation}, ${_vm.finalSize / 2}, ${_vm.finalSize / 2})`}},[_c('path',{ref:"path",staticClass:"path",attrs:{"d":`
          m ${_vm.finalSize / 2},${_vm.finalThickness}
          a ${_vm.radius},${_vm.radius} 0,1,1 0,${_vm.radius * 2}
          a ${_vm.radius},${_vm.radius} 0,1,1 0,${_vm.radius * -2}
          z
        `,"fill":"none","stroke-width":_vm.finalThickness,"stroke-dasharray":`${_vm.progressPathLength},${_vm.pathLength}`,"stroke-dashoffset":_vm.dashOffset}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }