import Vue from 'vue'

import VueRouter, { Route, RouteConfig } from 'vue-router'

import { store } from '@/assets/js/store.js'
import { EventBus } from '@/assets/js/helpers'
import feedbackDialogModule from '@/modules/feedback-dialog'
import userStore from '@/modules/users/store'
import { trackEvent } from '@/utils/googleAnalytics'
import { lassoxPortalUrl } from '@/lasso-urls'

// https://v3.router.vuejs.org/guide/advanced/lazy-loading.html
const About = () => import('./routes/About.vue')
const Company = () => import('./routes/Company/Company.vue')
const Contact = () => import('./routes/Contact.vue')
const Discover = () => import('./routes/Discover.vue')
const Faq = () => import('./routes/Faq.vue')
const Home = () => import('./routes/Home.vue')
const Job = () => import('./routes/Job.vue')
const List = () => import('./routes/List.vue')
const Person = () => import('./routes/Person.vue')
const Story = () => import('./routes/Story.vue')

Vue.use(VueRouter)

var companyMeta = { entityType: 'company', lassoIdPrefixes: 'CVR-1-' }
var personMeta = { entityType: 'person', lassoIdPrefixes: 'CVR-3-' }

const routes: RouteConfig[] = [
  { path: '/', name: 'home', component: Home },

  { path: '/login', name: 'login', redirect: () => {
    userStore.showLoginDialog({ initialRoute: 'login', trackingEventName: 'direct+link' })
    return '/'
  } },

  { path: '/signup', name: 'signup', redirect: to => {
    userStore.showLoginDialog({ initialRoute: 'signup', trackingEventName: 'direct+link' })
    return {
      path: '/',
      query: {},
    }
  } },

  { path: '/firmaer/:id/:name', name: 'company', component: Company, meta: companyMeta },
  { path: '/firmaer/:id/:storyTitle/:storyId/:optionalSecondPartOfStoryId?', name: 'companyStory', component: Story, meta: companyMeta },

  { path: '/personer/:id', name: 'person', component: Person, meta: personMeta },

  { path: '/discover', name: 'discover', component: Discover },

  { path: '/l/:listName', name: 'list', component: List },

  { path: '/faq', name: 'faq', component: Faq },
  { path: '/kontakt', name: 'contact', component: Contact },
  { path: '/om-lasso-news', name: 'about', component: About },
  { path: '/job', name: 'job', component: Job },

  { path: '*', redirect: '/' }
]

const router = new VueRouter({
  mode: 'history',
  routes: routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router

router.beforeEach((to, from, next) => {

  // Redirect to lassoX if logged in and on a page that has an X equivalent
  if (userStore.user && (to.name === 'companyStory' || to.name === 'company' || to.name === 'person')) {
    redirectToLassoX(to)

    next(false)
    return
  }

  if (to.hash === '#signup') {
    userStore.showLoginDialog({ initialRoute: 'signup', trackingEventName: 'direct+link' })
    return next({ params: to.params, path: to.path, query: to.query })
  } else if (to.hash === '#login') {
    userStore.showLoginDialog({ initialRoute: 'login', trackingEventName: 'direct+link' })
    return next({ params: to.params, path: to.path, query: to.query })
  }

  if (to.params.optionalSecondPartOfStoryId) to.params.storyId += '/' + to.params.optionalSecondPartOfStoryId
  if (to.query.hack) Vue.set(store.state, 'changeTeam', true)
  if (to.query.optIn) {
    EventBus.$emit('optIn', { token: to.query.optIn })
  }
  if (window.redirectUrl) {
    var r = window.redirectUrl
    window.redirectUrl = ''
    router.replace({ path: r })
    next(false)
  } else {
    // if (from.query.embedded && !to.query.embedded) next({ path: to.fullPath + '?embedded=true' }) // keep the embedded=true in the query string (if it's there)
    next()
  }
})

export function getCurrentLassoId() {
  return router.currentRoute.meta?.lassoIdPrefixes + router.currentRoute.params.id
}

export function redirectToLassoX(route: Route) {
  let lassoxRedirect = `${lassoxPortalUrl}/${route.meta?.lassoIdPrefixes}${route.params.id}/${
    route.name === 'companyStory' ? `news?providerId=lasso&storyId=${route.params.storyId}` : `dashboard`}`
  EventBus.$emit('trackGoal', {
    goalName: 'redirect user to platform',
    config: {
      props: {
        redirectUrl: lassoxRedirect
      },
      callback: () => location.href = lassoxRedirect
    }
  })
}


var sendPageView = () => {
  if (window.dataLayer) {
    // page view will only be picked up by Google Tag Manager (GTM) if consent is given. This logic is handled in GTM.
    window.dataLayer.push({ event: 'virtualPageview', virtualPageviewPath: document.location.pathname, virtualPageviewTitle: document.title })
  }
}

window.addEventListener('CookieInformationConsentGiven', function(event) {
  // send page view
  sendPageView()
})

router.afterEach((to, from) => {
  // only send pageview if we're coming from another lasso.dk page.
  // that's because we're triggering a pageview once cookie-consent is given, which happens right away on the first page (if consent is given)
  if (from.name) {
    setTimeout(function () {
      sendPageView();
    }, 10)
  }
})

router.onReady(() => {
  // below will trigger NPS survey
  // const showFeedbackDialog = (delay: number) => {
  //   setTimeout(() => {
  //     feedbackDialogModule.state.showDialog = true

  //     EventBus.$emit('trackEvent', {
  //       eventCategory: 'Feedback',
  //       eventAction: 'Show',
  //       eventLabel: router.currentRoute.fullPath,
  //     })
  //   }, delay)
  // }

  // if (router.currentRoute.name != 'Login') return

  // const status = feedbackDialogModule.storage.status.get()

  // if (status !== 'submitted' && status !== 'dismissed') {
  //   const cookiesAcceptedDate = (() => {
  //     const matches = document.cookie.match(/(^|;)\s*cookies_accepted=([^;]+)/)
  //     if (!matches) return null
  //     return new Date(matches[2])
  //   })()

  //   if (cookiesAcceptedDate) {
  //     const daysSinceCookiesAccepted = (Date.now() - cookiesAcceptedDate.getTime()) / 86400000
  //     if (daysSinceCookiesAccepted >= 1) showFeedbackDialog(1000)
  //   }
  // }
})
