<template>
  <LoginDialogTemplate
    v-if="show"
    class="login-to-show-keyfigures-dialog"
    :options="{
      ...options,
      redirectUrl,
    }"
  >
    <!-- TODO: Tekst (Jeppe) -->

     <template v-slot:intro-super-title>Få adgang til mere</template>

<!--    <template v-slot:intro-title>Få adgang til mere</template>-->

    <template v-slot:intro-text>
      Opret en gratis profil og se over<br>30 regnskabs- og nøgletal
    </template>
  </LoginDialogTemplate>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import LoginDialogTemplate from '@/components/LoginDialogTemplate/LoginDialogTemplate.vue'
import userStore from '@/modules/users/store'
import { lassoxPortalUrl } from '@/lasso-urls'

@Component({
  components: {
    LoginDialogTemplate,
  },
})

export default class LoginToShowKeyfiguresDialog extends Vue {
  get options () {
    return userStore.loginToShowKeyfiguresDialogOptions
  }

  get show () {
    return !!this.options
  }

  get redirectUrl () {
    if (!this.options?.lassoId) return null
    return `${lassoxPortalUrl}/${this.options.lassoId}/cvrkeyfigures`
  }
}
</script>
