import Vue, { type Component } from 'vue'

interface OverlayStoreState {
  overlaysMap: Map<symbol, OverlaysItem>
  overlays: OverlaysItem[]
}

interface OverlaysItem {
  key: symbol
  component: Component
}

const overlayStore = new Vue({
  data: (): OverlayStoreState => ({
    overlaysMap: new Map(),
    overlays: [],
  }),

  computed: {
    overlayIndexes (): Map<symbol, number> {
      return new Map(this.overlays.map(({ key }, index) => [ key, index ]))
    },
  },

  methods: {
    showOverlay (component: Component) {
      const key = Symbol()
      const overlaysItem: OverlaysItem = { key, component }
      this.overlaysMap.set(key, overlaysItem)
      this.overlays.push(overlaysItem)
      return {
        key,
        hide: () => this.hideOverlay(key),
      }
    },

    hideOverlay (key: symbol) {
      const index = this.overlayIndexes.get(key)
      this.overlaysMap.delete(key)
      if (index !== undefined) this.overlays.splice(index, 1)
    },
  },
})

export default overlayStore
