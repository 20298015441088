const env = (
  location.host.startsWith('localhost:') ? 'dev' :
  location.host.startsWith('staging.') ? 'staging' :
  'prod'
)

let lassoxApiUrl: string
let lassoxAppsUrl: string
let lassoxPortalUrl: string

switch (env) {
  case 'dev': {
    // * This should be used once lasso.dk, api, apps and portal work together on the same branch.
    // lassoxApiUrl = 'https://dev.api.lassox.com:444'
    // lassoxAppsUrl = 'https://dev.apps.lassox.com:5431'
    // lassoxPortalUrl = 'https://dev.portal.lassox.com:6543'

    // * This is temporarily used while this version of lasso.dk is only on the net6api branch.
    lassoxApiUrl = 'https://staging.api.lassox.com'
    lassoxAppsUrl = 'https://staging.apps.lassox.com'
    lassoxPortalUrl = 'https://staging.portal.lassox.com'
    break
  }

  case 'staging': {
    lassoxApiUrl = 'https://staging.api.lassox.com'
    lassoxAppsUrl = 'https://staging.apps.lassox.com'
    lassoxPortalUrl = 'https://staging.portal.lassox.com'
    break
  }

  case 'prod': {
    lassoxApiUrl = 'https://api.lassox.com'
    lassoxAppsUrl = 'https://apps.lassox.com'
    lassoxPortalUrl = 'https://portal.lassox.com'
    break
  }
}

export {
  lassoxApiUrl,
  lassoxAppsUrl,
  lassoxPortalUrl,
}
